@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "../variables.scss";

.customersInner {
  padding-left: 20px;
  padding-right: 20px;
}

.customersLogos {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  text-align: center;
  position: relative;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 110px;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

  @include mqMaxWidth($screen640) {
    bottom: 15px;
  }
}

.customer {
  flex: 1 0 calc(100% / 7);
  align-self: center;
  justify-content: center;
  padding: 32px 5px;

  @include mqMaxWidth($screen640) {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-basis: calc(100% / 3);
  }

  img {
    opacity: 0.7;
    max-height: 31px;
    width: auto;

    &:hover {
      opacity: 1;
    }

    @include mqMaxWidth($screen640) {
      max-width: 86px;
    }
  }
}

.customersButton {
  margin-top: 50px;
  text-align: center;

  @include mqMaxWidth($screen640) {
    margin-top: 30px;
  }
}

.exploreButton {
  transition: background-color $animationTime + ms;
}
