@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "../variables.scss";

.feedbacks {
  position: relative;
  overflow: hidden;
}

.feedbacksWrp {
  text-align: center;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  :global(.slick-list) {
    overflow: visible;
    margin-bottom: 20px;
  }

  :global(.slick-dots li) {
    margin: 0 10px;
  }
}

.feedback {
  width: 100%;
  padding: 10px;
  outline: 0;
}

.feedbackCard {
  position: relative;
  min-height: 220px;
  text-align: left;
  margin-bottom: 0;
  padding: 20px;
}

.feedbackLogo {
  height: 29px;
  margin-bottom: 25px;
}

.feedbackText {
  font-size: 14px;
  line-height: 18px;
  color: $baseTextColor;
}

.feedbackAuthor {
  position: absolute;
  bottom: 20px;
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $secondaryColor;
}

.grab {
  > div {
    cursor: grab;
  }
}

.modernCard {
  background-color: $secondary5Color;
  border: 1px solid $defaultTableBorderColor;
  border-radius: 5px;
  letter-spacing: 0.01em;

  transition: background-color $animationTime + ms;

  &:hover {
    background-color: #fff;
    border-color: $btnDefaultColorHover;
  }
}
