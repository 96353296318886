@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/dataroom/ui/components/Landing/dealvdr/variables.scss";

.intro {
  background: $dealvdrGradient;
}

.introAnimatedTitle {
  color: #fffb84;
  text-align: left;

  @include mqMaxWidth(1300px) {
    text-align: center;
  }

  @include mqMaxWidth(1068px) {
    text-align: left;
  }

  @include mqMaxWidth(693px) {
    text-align: center;
  }

  @include mqMaxWidth($screen640) {
    text-align: left;
  }

  @include mqMaxWidth(464px) {
    text-align: center;
  }
}

.description {
  max-width: 950px;
}
