@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.headerWrapper {
  margin-bottom: 18px;


  @include mqMaxWidth($screen640) {
    margin-bottom: 0;
  }
}
