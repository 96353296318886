@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.sliderContainer {
  display: inline-flex;
  height: 55px;
  overflow: hidden;

  @include mqMaxWidth($screen640) {
    height: 33px;
  }
}

.sliderList {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;

  &.sliderCount3 {
    animation: sliderCount3Animation 8s ease infinite;
  }

  &.sliderCount4 {
    animation: sliderCount4Animation 8s ease infinite;
  }
  &.sliderCount5 {
    animation: sliderCount5Animation 8s ease infinite;
  }

  span {
    display: block;
    height: 55px;
    line-height: 52px;

    @include mqMaxWidth($screen640) {
      height: 33px;
      line-height: 33px;
    }
  }
}

@keyframes sliderCount3Animation {
  0%,
  28% {
    transform: translateY(0%);
  }
  32%,
  60% {
    transform: translateY(-100%);
  }
  65%,
  97% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(-300%);
  }
}

@keyframes sliderCount4Animation {
  0%,
  19% {
    transform: translateY(0%);
  }
  25%,
  44% {
    transform: translateY(-100%);
  }
  50%,
  69% {
    transform: translateY(-200%);
  }
  75%,
  93% {
    transform: translateY(-300%);
  }
  100% {
    transform: translateY(-400%);
  }
}

@keyframes sliderCount5Animation {
  0%,
  15% {
    transform: translateY(0%);
  }
  20%,
  35% {
    transform: translateY(-100%);
  }
  40%,
  55% {
    transform: translateY(-200%);
  }
  60%,
  75% {
    transform: translateY(-300%);
  }
  80%,
  95% {
    transform: translateY(-400%);
  }
  100% {
    transform: translateY(-500%);
  }
}
