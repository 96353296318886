@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.slickSliderEncapsulationGlobalStyles {
  :global {
    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      user-select: none;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;

      &:focus {
        outline: none;
      }

      &.dragging {
        cursor: hand;
      }
    }


    .slick-slider .slick-track,
    .slick-slider .slick-list {
      transform: translate3d(0, 0, 0);
    }

    .slick-track {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;

      &:before,
      &:after {
        display: table;
        content: "";
      }

      &:after {
        clear: both;
      }
    }

    .slick-slide {
      display: none;
      float: left;
      outline: 0;
      height: 100%;
      min-height: 1px;
    }

    .slick-initialized .slick-slide {
      display: block;
    }

    .slick-dots {
      display: block;
      list-style: none;
      text-align: center;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        position: relative;
        display: inline-block;
        height: 6px;
        width: 6px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
          border: 0;
          background-color: #e1e1e1;
          display: block;
          height: 6px;
          width: 6px;
          outline: none;
          line-height: 0;
          font-size: 0;
          color: transparent;
          padding: 0;
          cursor: pointer;
          border-radius: 50%;
        }

        &.slick-active {
          button {
            background-color: #616161;
            opacity: 1;
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      outline: none;
      border-width: 0;
      background: transparent;

      &:hover,
      &:focus {
        color: transparent;
        outline: none;
        background: transparent;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        font-size: 20px;
        line-height: 1;
        opacity: 0.75;
        color: $actionColor;
      }

      &.slick-disabled {
        &:before {
          opacity: 0.25;
        }
      }
    }

    .arrow-style {
      position: relative;
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      border-right: 2px solid $actionColor;
      border-top: 2px solid $actionColor;
    }

    .slick-prev {
      left: -35px;

      &:before {
        @extend .arrow-style;
        transform: rotate(225deg);
      }
    }

    .slick-next {
      right: -35px;

      &:before {
        @extend .arrow-style;
        transform: rotate(45deg);
      }
    }
  }
}
