@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/UI/Pages/Landing/Sections/variables.scss";

.intro {
  position: relative;
  padding-top: 100px;
  overflow: hidden;

  @include mqMaxWidth($screen640) {
    padding: 40px 0;
  }
}

.introContainer {
  width: 100%;
  max-width: 1540px;
  margin: 0 auto;

  position: relative;
  z-index: 2;

  padding-left: 40px;
  padding-right: 40px;
}

.introContent {
  text-align: center;
  margin: 0 auto 100px;
  max-width: 1450px;

  @include mqMaxWidth($screen640) {
    margin-bottom: 0;
  }
}

.introTitle {
  font-family: $baseFontFamily;
  font-size: 48px;
  line-height: 52px;
  color: #fff;
  font-weight: bold;
  letter-spacing: -0.02em;
  margin: 0;

  @include mqMaxWidth($screen640) {
    font-size: 30px;
    line-height: 33px;
  }
}

.introDescription {
  margin: 0 auto;
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #fff;

  p {
    margin: 30px auto;

    @include mqMaxWidth($screen640) {
      margin: 20px 0;

      font-size: 16px;
      line-height: 20px;
    }
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  @include mqMaxWidth(1024px) {
    width: 100%;
  }

  @include mqMaxWidth($screen640) {
    font-size: 15px;
    line-height: 18px;
  }
}

.videoSection {
  overflow: hidden;
  max-width: 1196px;
  margin: 0 auto -12%;

  box-shadow: 0 0 44px rgba(0, 0, 0, 0.2);

  .videoWrp {
    margin-right: -1px;
  }

  video {
    border-radius: 5px;
  }

  @include mqMaxWidth($screen640) {
    display: none;
  }
}

.wavesWrp {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
}

.wavesImage {
  opacity: 1;
}

.shadow {
  position: absolute;
  z-index: 2;
  bottom: 0;

  width: 100%;
  height: 100px;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(198, 198, 198, 0.2) 100%);

  @include mqMaxWidth($screen640) {
    display: none;
  }
}


.typingCursor:after {
  content: '|';
  line-height: 20px;
}

.typingCursor.blinking:after {
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
